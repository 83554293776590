import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
export const _frontmatter = {
  "key": 9,
  "title": "Kinderen Geven Ijsjes aan Agenten",
  "type": "audiovisual",
  "date": "2019",
  "description": "Movies That Matter is a Dutch film festival that shows only human rights films to stur a conversation about conflict areas in this world. For this trailer I wanted to emphasize the power of film over the usual dry news articles we read every day. Film makes us able to make a real human connection",
  "image": "../../static/img/work/moviesThatMatter/prep-1.jpg",
  "video": "https://player.vimeo.com/video/306260288",
  "alt": "film still",
  "runningTime": "8:54",
  "draft": true,
  "rating": 3
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Gallery width={75} mdxType="Gallery">
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      